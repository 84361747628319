import { IModel } from '@alberta/konexi-shared';
import { Paginated, Query } from '@feathersjs/feathers';
import { IGenericStorage } from 'src/app/shared/services/contracts/database/generic-storage';
import { IQuery } from 'src/app/shared/services/contracts/query/query';
import { IFeathersAppProvider } from 'src/app/shared/services/contracts/sync/feathers-app-provider';

import { RawOptions } from '../contracts/repository/raw-options';
import { AggregateStage } from './aggregate-stage.interface';
import { DbQueryStrategy } from './db-query-strategy';

export class RemoteQueryStrategy<T extends IModel & {}> extends DbQueryStrategy<T> {
  constructor(
    databaseName: string,
    storage: IGenericStorage,
    feathersAppProvider: IFeathersAppProvider,
    user,
    plugins: any
  ) {
    super(databaseName, storage, feathersAppProvider, user, plugins);
  }

  public async getAll(): Promise<T[]> {
    const result = await this.feathersAppProvider.app
      .service(this.table)
      .find(this.getParamsWithAuth(this.user, { query: { $paginate: false } }));

    return this.isPaginated(result) ? result.data : result;
  }

  public async getItems(keys: string[]): Promise<any[]> {
    const result = await this.feathersAppProvider.app
      .service(this.table)
      .find(this.getParamsWithAuth(this.user, { query: { _id: { $in: keys }, $paginate: false } }));

    return this.isPaginated(result) ? result.data : result;
  }

  public async get(id: string): Promise<T> {
    try {
      const result = await this.feathersAppProvider.app.service(this.table).get(id, this.getParamsWithAuth(this.user));

      return result;
    } catch (e) {
      return void 0;
    }
  }

  public async searchInternal(ids: string[], query: IQuery): Promise<T[]> {
    const params = {
      query: {
        _id: { $in: ids },
        archived: this.withArchive.includes(this.table) ? undefined : { $ne: true },
        $paginate: false,
      },
    };

    if (query.ignoreRegionIds) {
      params.query['$ignoreRegionIds'] = true;
    }

    if (query.ignoreMetadataType) {
      params.query['$ignoreMetadataType'] = true;
    }

    const result = await this.feathersAppProvider.app
      .service(this.table)
      .find(this.getParamsWithAuth(this.user, params));

    return this.isPaginated(result) ? result.data : result;
  }

  public async searchInternalByQuery(
    query: Query,
    aggregate: AggregateStage[],
    ignoreRegionIds: boolean,
    ignoreMetadataType?: boolean,
    sort?: { [key: string]: number }
  ) {
    const params = {
      query: {
        ...query,
        archived: this.withArchive.includes(this.table) ? undefined : { $in: [null, false] },
        $paginate: false,
      },
    };

    if (ignoreRegionIds) {
      params.query['$ignoreRegionIds'] = true;
    }

    if (ignoreMetadataType) {
      params.query['$ignoreMetadataType'] = true;
    }

    if (sort) {
      params.query['$sort'] = sort;
    }

    const paramsWith$client = this.getParamsWithAuth(this.user, params);
    if (aggregate) {
      if (!this.withArchive.includes(this.table)) {
        aggregate.push({ $match: { archived: { $in: [null, false] } } });
      }
      paramsWith$client.query.$client.syncData.aggregate = aggregate;
    }

    const result = await this.feathersAppProvider.app.service(this.table).find(paramsWith$client);

    return this.isPaginated(result) ? result.data : result;
  }

  public async raw({
    aggregation,
    query = {},
    options,
  }: {
    aggregation?: any[];
    query?: any;
    options?: RawOptions;
  }): Promise<any> {
    if (aggregation && query && Object.keys(query).length > 0) {
      throw new Error('Cannot use aggregation and query at the same time');
    }
    if (options?.ignoreRegionIds) {
      query['$ignoreRegionIds'] = true;
    }
    const paramsWith$client = this.getParamsWithAuth(this.user, { query });
    if (aggregation) {
      paramsWith$client.query.$client.syncData.aggregate = aggregation;
    }

    if (!options?.timeout) {
      const result = await this.feathersAppProvider.app.service(this.table).find(paramsWith$client);
      return this.isPaginated(result) ? result.data : result;
    }

    return new Promise(async (resolve, reject) => {
      const timeoutHandle = setTimeout(() => {
        reject('timeout');
      }, options.timeout);
      const result = await this.feathersAppProvider.app.service(this.table).find(paramsWith$client);
      clearTimeout(timeoutHandle);
      // TODO: Handle pagination correctly. Currently only the first 'page' of a paginated result is returned.
      // We should request all pages iteratively and return the combined result.
      resolve(this.isPaginated(result) ? result.data : result);
    });
  }

  private isPaginated(paginated: any[] | Paginated<any>): paginated is Paginated<any> {
    return paginated && (paginated as Paginated<any>).data !== undefined;
  }
}
